import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

// UI
import LoadingPage from 'ui/LoadingPage';
import MaintenancePage from 'ui/MaintenancePage';
// Ajax
import { loginGuestUser } from 'ajax/guestAuth';
// State
import { profileState } from 'state';

const LazyGuestBookingPage = React.lazy(() => import('components/Booking/GuestCreate'));

function GuestBookingPage() {
  const [, setProfile] = useRecoilState(profileState);
  const [maintenance, setMaintenance] = useState({
    online: true,
    message: 'Application is currently offline for maintenance.',
    isAdmin: false
  });
  // Login as Guest User
  const guestLoginHandler = () => {
    loginGuestUser()
      .then((res) => {
        if (!res || res.status > 500) {
          const { message } = JSON.parse(res?.response);
          setIsLoggedIn(true);
          return setMaintenance({
            online: false,
            message: message || 'This application is currently offline for maintenance.',
            isAdmin: false
          });
        }
        if (res.status === 200 && res.requestProfile?.status === 200) {
          const profile = res.requestProfile.data;
          setProfile(profile);
          setMaintenance({
            online: profile.options.maintenance.online,
            message: profile.options.maintenance.message || 'This application is currently offline for maintenance.',
            isAdmin: profile.role.value === 'Administrator'
          });
          return setIsLoggedIn(true);
        }
        if (res.status === 401) return navigate('/login');
        return toast.error('Something went wrong, please try again later.');
      })
      .catch((error) => {
        toast.error('Something went wrong, please try again later.');
        return error;
      });
  };

  // Loggin In Guest
  useEffect(() => {
    guestLoginHandler();
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  return (
    <>
      <LoadingPage hide={isLoggedIn} />
      {!maintenance.online && <MaintenancePage isAdmin={maintenance.isAdmin} message={maintenance.message} />}
      {maintenance.online && (
        <React.Suspense fallback={<span />}>{isLoggedIn && <LazyGuestBookingPage />}</React.Suspense>
      )}
    </>
  );
}

export default GuestBookingPage;

export function Head() {
  return <title>Create Booking - Veezu</title>;
}
